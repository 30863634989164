<template>
  <div class="h-patient-height">
    <!-- Page-Title -->
    <div class="h-add-patient h-padding-top-xl h-mobile-padding-left-l h-mobile-padding-right-l">
      <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto">
        <div>
          <div :class="{ 'd-none': loading }">
            <div
              v-if="formWasSaved === false"
              class="alert alert-danger border-0 alert-dismissible mb-4"
              role="alert">
              Failed to save data. Please view the form to see errors.
            </div>
            <div
              v-if="formWasSaved === true"
              class="alert alert-success border-0 alert-dismissible mb-4"
              role="alert">
              Patient data was saved.
            </div>

            <form class="h-form-btn">
              <div class="step-app" id="AddPatient">
                <div class="step-content">
                  <div class="step-tab-panel" data-step="step1">
                    <section>
                      <div v-if="!client.id" class="h-row">
                        <div
                          class="h-input-w49
                           h-desktop-margin-right-2
                            h-col-mobile-12
                             h-margin-bottom-l">
                          <label class="h-label"> Hospital: </label>
                          <HospitalSelector v-model="hospitalId" @hospital="setHospital" />
                        </div>

                        <div
                          class="h-input-w49
                           h-col-mobile-12
                            h-margin-bottom-l">
                          <label class="h-label"> Location: </label>
                          <InputField
                            type="select"
                            v-model="locationId"
                            :choices="locationChoices" />
                        </div>
                      </div>

                      <div class="h-row">
                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Name: </label>
                          <InputField
                            id="input_name"
                            type="text"
                            v-model="client.name"
                            :error="errors.name"
                            @input="() => {
                              errors.name = null;
                            }
                              " />
                          <div :class="['invalid-feedback', { 'd-block': errors.name }]">
                            {{ errors.name }}
                          </div>
                        </div>
                        <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label"> Date of birth:</label>
                          <InputField
                            id="DateOfBirth"
                            name="birthday"
                            type="date"
                            v-model="client.date_of_birth"
                            :error="errors.date_of_birth"
                            @input="() => {
                              errors.date_of_birth = null;
                            }
                              " />
                          <div
                            class="invalid-feedback notDateOfBirth"
                            style="display: none"
                            :class="[{ 'd-block': errors.date_of_birth }]">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                           h-desktop-margin-right-2
                           h-col-mobile-12
                           h-margin-bottom-l
                           h-phone-new-patient">
                          <label class="h-label"> Phone +1 (234) 567-8900:</label>
                          <VuePhoneNumberInput
                            v-model="client.phone"
                            @update="updateFormattedPhone"
                            :error="errors.phone"
                            @input="() => {
                              errors.phone = null;
                            }
                              " />
                          <div :class="['invalid-feedback', { 'd-block': errors.phone }]">
                            {{ errors.phone }}
                          </div>
                          <div style="margin-top: 8px">
                            <InputField
                              label="Accepts SMS"
                              type="checkbox"
                              id="send_sms"
                              v-model="client.send_sms" />
                          </div>
                        </div>
                        <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label">Gender:</label>
                          <div
                            :class="[
                              'h-d-flex',
                              'h-gender-radio',
                              { 'error-class': errors.gender }
                            ]">
                            <div
                              class="h-custom-radio-btn"
                              :class="[{ 'h-radio-gray': !client.gender }]">
                              <div class="h-custom-checkbox">
                                <input
                                  type="radio"
                                  id="male"
                                  value="male"
                                  class="custom-control-input"
                                  v-model="client.gender"
                                  :error="errors.gender"
                                  @input="() => {
                                    errors.gender = null;
                                  }
                                    " />
                                <label for="male" class="custom-control-label cursor-pointer">Male</label>
                              </div>
                            </div>
                            <div
                              class="h-custom-radio-btn"
                              :class="[{ 'h-radio-gray': !client.gender }]">
                              <div class="h-custom-checkbox">
                                <input
                                  type="radio"
                                  id="female"
                                  value="female"
                                  class="custom-control-input"
                                  v-model="client.gender"
                                  :error="errors.gender"
                                  @input="() => {
                                    errors.gender = null;
                                  }
                                    " />
                                <label for="female" class="custom-control-label cursor-pointer">Female</label>
                              </div>
                            </div>
                          </div>
                          <div :class="['invalid-feedback', { 'd-block': errors.gender }]">
                            {{ errors.gender }}
                          </div>
                        </div>
                        <div
                          class="h-input-w49
                           h-desktop-margin-right-2
                           h-col-mobile-12
                           h-margin-bottom-l">
                          <label class="h-label"> Height (inches): </label>
                          <InputField
                            type="number"
                            v-model="defaultHeight"
                            :error="errors.height"
                            @input="() => {
                              errors.height = null;
                            }
                              " />
                        </div>

                        <div
                          class="h-input-w49
                        h-col-mobile-12
                        h-margin-bottom-l">
                          <label class="h-label"> Weight (lbs):</label>
                          <InputField
                            type="number"
                            v-model="defaultWeight"
                            :error="errors.weight"
                            @input="() => {
                              errors.weight = null;
                            }
                              " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Physician:</label>
                          <InputField
                            type="select"
                            class="h-select-new-patient"
                            :choices="surgeonOptions"
                            v-model="surgeonId"
                            :error="errors.surgeon_id"
                            @input="() => {
                              errors.surgeon_id = null;
                            }
                              " />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">Billing Physician:</label>
                          <InputField
                            type="select"
                            class="h-select-new-patient"
                            :choices="surgeonOptions"
                            v-model="billingPhysicianId"
                            :error="errors.billing_physician_id"
                            @input="() => {
                              errors.billing_physician_id = null;
                            }
                              " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-desktop-margin-right-2
                          h-margin-bottom-l">
                          <label class="h-label"> Plan Start Date:</label>
                          <InputField
                            type="date"
                            id="PlanStartDate"
                            v-model="today_plan_start_date"
                            :error="errors.plan_start_date"
                            @input="() => {
                              errors.plan_start_date = null;
                            }
                              " />
                          <div class="invalid-feedback notPlanEndDate" style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Plan End Date:</label>
                          <InputField
                            type="date"
                            id="PlanEndDate"
                            v-model="year_plan_end_date"
                            :error="errors.plan_end_date"
                            @input="() => {
                              errors.plan_end_date = null;
                            }
                              " />

                          <div class="invalid-feedback notPlanEndDate" style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>
                        <div
                          v-if="shouldAssignedNurseDropDownBeShown"
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Assigned care provider:</label>
                          <InputField
                            type="select"
                            class="h-select-new-patient"
                            :choices="nurseOptions"
                            v-model="assignedNurseId"
                            :error="errors.assigned_nurse_id"
                            @input="() => {
                              errors.assigned_nurse_id = null;
                            }
                              " />
                        </div>
                        <div
                          class="h-d-flex h-w-100
                          flex-wrap h-bg-w
                          h-padding-xl
                          h-margin-bottom-l
                          h-care-protocol-card">
                          <div
                            class="h-col-desktop-12 h-col-mobile-12"
                            :class="[
                              {
                                'h-padding-bottom-l': this.client.monitoring_program != null
                              }
                            ]">
                            <label class="h-label">Monitoring program:</label>
                            <div
                              :class="[
                                'h-d-flex',
                                'h-Monitoring-program-radio',
                                { 'error-class': errors.monitoring_program }
                              ]">
                              <div
                                class="h-custom-radio-btn"
                                :class="[
                                  {
                                    'h-radio-gray': !this.client.monitoring_program
                                  }
                                ]">
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    id="CCM"
                                    value="CCM"
                                    class="custom-control-input"
                                    v-model="client.monitoring_program"
                                    :error="errors.monitoring_program"
                                    @input="errors.monitoring_program = null" />
                                  <label for="CCM" class="custom-control-label cursor-pointer">CCM</label>
                                </div>
                              </div>
                              <div
                                class="h-custom-radio-btn"
                                :class="[
                                  {
                                    'h-radio-gray': !this.client.monitoring_program
                                  }
                                ]">
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    id="PCM"
                                    value="PCM"
                                    class="custom-control-input"
                                    v-model="client.monitoring_program"
                                    :error="errors.monitoring_program"
                                    @input="errors.monitoring_program = null" />
                                  <label for="PCM" class="custom-control-label cursor-pointer">PCM</label>
                                </div>
                              </div>
                              <div
                                class="h-custom-radio-btn"
                                :class="[
                                  {
                                    'h-radio-gray': !this.client.monitoring_program
                                  }
                                ]">
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    id="RPM"
                                    value="RPM"
                                    class="custom-control-input"
                                    v-model="client.monitoring_program"
                                    :error="errors.monitoring_program"
                                    @input="errors.monitoring_program = null" />
                                  <label for="RPM" class="custom-control-label cursor-pointer">RPM</label>
                                </div>
                              </div>
                              <div
                                class="h-custom-radio-btn"
                                :class="[
                                  {
                                    'h-radio-gray': !this.client.monitoring_program
                                  }
                                ]">
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    id="RTM"
                                    value="RTM"
                                    class="custom-control-input"
                                    v-model="client.monitoring_program"
                                    :error="errors.monitoring_program"
                                    @input="errors.monitoring_program = null" />
                                  <label for="RTM" class="custom-control-label cursor-pointer">RTM</label>
                                </div>
                              </div>
                              <div
                                class="h-custom-radio-btn"
                                :class="[
                                  {
                                    'h-radio-gray': !this.client.monitoring_program
                                  }
                                ]">
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    id="Wellness"
                                    value="Wellness"
                                    class="custom-control-input"
                                    v-model="client.monitoring_program"
                                    :error="errors.monitoring_program"
                                    @input="errors.monitoring_program = null" />
                                  <label for="Wellness" class="custom-control-label cursor-pointer">Wellness</label>
                                </div>
                              </div>

                            </div>
                            <div
                              :class="[
                                'invalid-feedback',
                                { 'd-block': errors.monitoring_program }
                              ]">
                              {{ errors.monitoring_program }}
                            </div>
                            <hr
                              v-if="this.client.monitoring_program != null"
                              class="h-hr h-margin-bottom-l h-margin-top-l" />
                          </div>
                          <div class="h-d-flex h-gap-xl h-w-100">
                            <div
                              v-if="
                                this.client.monitoring_program === 'RTM' ||
                                this.client.monitoring_program === 'RPM'
                              "
                              class="h-col-desktop-6 h-col-mobile-6 h-margin-bottom-l">
                              <label class="h-label">Care protocol with medications?</label>
                              <div
                                :class="[
                                  'h-d-flex',
                                  'h-Monitoring-program-radio',
                                  {
                                    'error-class': errors.CareProtocolMedications
                                  }
                                ]">
                                <div class="h-custom-radio-btn h-margin-right-l">
                                  <div class="h-custom-checkbox">
                                    <input
                                      type="radio"
                                      id="YesMedications"
                                      value="Yes"
                                      class="custom-control-input"
                                      v-model="CareProtocolMedications"
                                      :error="errors.CareProtocolMedications"
                                      @input="clearError" />
                                    <label
                                      for="YesMedications"
                                      class="custom-control-label cursor-pointer">Yes</label>
                                  </div>
                                </div>
                                <div class="h-custom-radio-btn">
                                  <div class="h-custom-checkbox">
                                    <input
                                      type="radio"
                                      id="NoMedications"
                                      value="No"
                                      class="custom-control-input"
                                      v-model="CareProtocolMedications"
                                      :error="errors.CareProtocolMedications"
                                      @input="clearError" />
                                    <label
                                      for="NoMedications"
                                      class="custom-control-label cursor-pointer">No</label>
                                  </div>
                                </div>
                              </div>
                              <div
                                :class="[
                                  'invalid-feedback',
                                  { 'd-block': errors.CareProtocolMedications }
                                ]">
                                {{ errors.CareProtocolMedications }}
                              </div>
                            </div>
                            <div
                              v-if="
                                this.client.monitoring_program === 'RTM' ||
                                this.client.monitoring_program === 'RPM'
                              "
                              class="h-col-desktop-6 h-col-mobile-6 h-margin-bottom-l">
                              <label class="h-label">Care protocol with physical therapy?</label>
                              <div
                                :class="[
                                  'h-d-flex',
                                  'h-Monitoring-program-radio',
                                  { 'error-class': errors.CareProtocolPT }
                                ]">
                                <div class="h-custom-radio-btn h-margin-right-l">
                                  <div class="h-custom-checkbox">
                                    <input
                                      type="radio"
                                      id="YesPT"
                                      value="Yes"
                                      class="custom-control-input"
                                      v-model="CareProtocolPT"
                                      :error="errors.CareProtocolPT"
                                      @input="clearError" />
                                    <label for="YesPT" class="custom-control-label cursor-pointer">Yes</label>
                                  </div>
                                </div>
                                <div class="h-custom-radio-btn">
                                  <div class="h-custom-checkbox">
                                    <input
                                      type="radio"
                                      id="NoPT"
                                      value="No"
                                      class="custom-control-input"
                                      v-model="CareProtocolPT"
                                      :error="errors.CareProtocolPT"
                                      @input="clearError" />
                                    <label for="NoPT" class="custom-control-label cursor-pointer">No</label>
                                  </div>
                                </div>
                              </div>
                              <div
                                :class="['invalid-feedback', { 'd-block': errors.CareProtocolPT }]">
                                {{ errors.CareProtocolPT }}
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="this.client.monitoring_program === 'RTM'"
                            class="h-col-desktop-12 h-col-mobile-12 h-margin-bottom-l">
                            <label class="h-label">Care type:</label>
                            <div
                              :class="[
                                'h-d-flex',
                                'flex-wrap',
                                'h-last-left',
                                'h-2btn-gap-l',
                                'h-Monitoring-program-radio',
                                { 'error-class': errors.CareType }
                              ]">
                              <!-- Use v-for here -->
                              <div
                                class="h-custom-radio-btn h-custom-radio-btn-short"
                                v-for="(careType, index) in careTypes"
                                :key="index">
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    :id="`careType-${index}`"
                                    :value="careType"
                                    class="custom-control-input"
                                    v-model="selectedCareType"
                                    :error="errors.CareType"
                                    @input="clearError" />
                                  <label
                                    :for="`careType-${index}`"
                                    class="custom-control-label cursor-pointer">
                                    {{ careType }}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div :class="['invalid-feedback', { 'd-block': errors.CareType }]">
                              {{ errors.CareType }}
                            </div>
                          </div>

                          <div
                            v-if="this.client.monitoring_program != null"
                            class="h-w-100 h-col-mobile-12">
                            <label class="h-label">
                              Care protocol<span v-if="client.id">
                                (if changed, patient health status will also be updated) </span>:
                            </label>
                            <div class="h-d-flex" :class="{ 'h-select-care': isDisabled }">
                              <multiselect
                                v-model="selectedCareProtocols"
                                :options="careProtocols"
                                :placeholder="'Select Care protocol'"
                                :multiple="false"
                                track-by="value"
                                label="label"
                                :disabled="isDisabled"
                                :class="{ error: errors.care_protocol_id }"
                                @input="clearError">
                                <template v-slot:option="{ option }">
                                  {{ option.label }}
                                </template>
                                <template v-slot:singleLabel="{ option }">
                                  {{ option.label }}
                                </template>
                              </multiselect>
                              <button data-tooltip="Change the automatically chosen protocol." v-if="isDisabled" class="h-btn h-btn-dangerous" @click="enableSelection">
                                Overwrite
                              </button>
                            </div>
                            <div :class="['invalid-feedback', { 'd-block': errors.care_protocol_id }]">
                              {{ errors.care_protocol_id }}
                            </div>
                          </div>
                        </div>
                        <div class="h-col-mobile-12 h-margin-bottom-l w-100">
                          <label class="h-label"> Pain categories: </label>
                          <PainCategories v-model="client.pain_categories" />
                        </div>
                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <h3 class="h-label h-text-left h-margin-bottom-m">
                            Turn on all default patient reported outcome surveys
                          </h3>
                          <div class="h-d-flex h-space-between" :class="{
                            'error-class': errors.assessments_off_selected
                          }">
                            <div :class="{
                              'h-margin-right-l': index === 0,
                              'h-radio-gray': !assessments_off_selected
                            }" class="h-custom-radio-btn" v-for="(option, index) in options" :key="index">
                              <div class="h-custom-checkbox">
                                <input type="radio" :id="index" :value="option" class="custom-control-input" v-model="assessments_off_selected" :error="errors.assessments_off_selected" @input="() => {
                                  errors.assessments_off_selected = null;
                                }
                                  " />
                                <label :for="index" class="custom-control-label cursor-pointer">{{
                                  option
                                }}</label>
                              </div>
                            </div>
                          </div>
                          <div :class="[
                            'invalid-feedback',
                            { 'd-block': errors.assessments_off_selected }
                          ]">
                            {{ errors.assessments_off_selected }}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="step-tab-panel top-120" data-step="step2">
                    <section>
                      <div class="h-row">
                        <div class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l
                          h-margin-top-l">
                          <h3 class="h-h3
                            h-primary_shade_1
                            h-text-left
                            h-margin-bottom-m">
                            Diagnosis: (optional)
                          </h3>
                          <Diagnosis :client="client" v-if="client.diagnoses" />

                          <h3 class="h-h3
                            h-primary_shade_1
                            h-text-left
                            h-margin-bottom-m
                            h-margin-top-l">
                            Medication use: (optional)
                          </h3>
                          <MedicationUse v-if="client.medication_use_template" :client="client" :errors="errors.medication_use_template || {}" />
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="step-tab-panel top-120" data-step="step3">
                    <section>
                      <div class="h-row">
                        <h3 class="h-h3
                          h-primary_shade_1
                          h-text-left
                          h-margin-bottom-l">
                          Extra fields: (optional)
                        </h3>
                      </div>
                      <div class="h-row">
                        <div class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <form v-if="clientSet" method="post">
                            <div class="photo-wrapper">
                              <input type="file" id="input-file-now-custom-1" class="dropify" :data-default-file="this.photoUrl || '/assets/images/users/patient-pro.png'
                                " ref="photo" />
                            </div>
                            <p v-if="errors.photo" class="h-h5 h-bold h-dangerous h-margin-top-s">
                              Photo: {{ errors.photo }}
                            </p>
                          </form>
                        </div>
                      </div>
                      <div class="h-row">
                        <div class="h-col-desktop-6
                          h-col-mobile-6
                          h-margin-bottom-l">
                          <InputField label="SCS" type="checkbox" id="scs" v-model="client.scs" :error="errors.scs" @input="errors.scs = null" />
                        </div>
                        <div class="h-col-desktop-6
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <InputField label="Is traced" type="checkbox" id="is_traced" v-model="client.is_traced" :error="errors.is_traced" @input="errors.is_traced = null" />
                        </div>
                        <div v-if="client.scs" class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <div class="h-row">
                            <div class="h-input-w49
                              h-desktop-margin-right-2
                              h-col-mobile-12
                              h-margin-bottom-l">
                              <label class="h-label"> SCS date: (optional)</label>
                              <InputField type="date" id="scs_date" v-model="client.scs_date" :error="errors.scs_date" @input="errors.scs_date = null" />
                            </div>
                            <div class="h-input-w49
                              h-col-mobile-12
                              h-margin-bottom-l">
                              <label class="h-label"> SCS satisfaction:</label>
                              <InputField type="select" :choices="[
                                { value: null, title: '--- select ---' },
                                { value: 1, title: '1' },
                                { value: 2, title: '2' },
                                { value: 3, title: '3' },
                                { value: 4, title: '4' },
                                { value: 5, title: '5' }
                              ]" v-model="client.scs_satisfaction" :error="errors.scs_satisfaction" @input="errors.scs_satisfaction = null" />
                            </div>
                          </div>
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">Address: (optional)</label>

                          <InputField type="text" v-model="client.address" :error="errors.address" @input="() => {
                            errors.address = null;
                          }
                            " />
                        </div>
                        <div class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> E-Mail: (optional)</label>
                          <InputField id="inviteEmail" type="text" v-model="client.email" :error="errors.email" @input="() => {
                            errors.email = null;
                          }
                            " />
                          <div id="notanEmail" class="invalid-feedback">
                            The entered email is not accepted
                          </div>
                        </div>
                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Mobile Type (Android, iPhone): (optional)
                          </label>
                          <InputField type="text" v-model="client.mobile_type" :error="errors.mobile_type" @input="() => {
                            errors.mobile_type = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Primary Device Type: (optional)</label>
                          <InputField type="text" v-model="client.primary_device" :error="errors.primary_device" @input="() => {
                            errors.primary_device = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Surgery date: (optional)</label>
                          <InputField type="date" id="SurgeryDate" v-model="client.surgery_date" :error="errors.surgery_date" @input="() => {
                            errors.surgery_date = null;
                          }
                            " />
                          <div class="invalid-feedback notSurgeryDate" style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l" v-if="newPatient === false">
                          <label class="h-label"> Account status: </label>
                          <InputField type="select" :choices="accountStatusChoices" v-model="accountStatus" :key="accountStatus" @input="() => {
                            errors.accountStatus = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Surgery time: (optional)</label>
                          <InputField type="time" id="SurgeryTime" v-model="client.surgery_time" :error="errors.surgery_time" @input="() => {
                            errors.surgery_time = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Discharge date: (optional:)</label>
                          <InputField type="date" id="DischargeDate" v-model="client.discharge_date" :error="errors.discharge_date" @input="() => {
                            errors.discharge_date = null;
                          }
                            " />
                          <div class="invalid-feedback notSurgeryDate" style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">Status: </label>
                          <InputField type="select" v-model="patient_status_id" :choices="patientStatus" />
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label h-desktop-padding-bottom-m">
                            Outstanding Issues or Concerns: (optional)
                          </label>
                          <InputField type="textarea" v-model="client.outstanding_issues_or_concerns" :error="errors.outstanding_issues_or_concerns" @input="() => {
                            errors.outstanding_issues_or_concerns = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label h-desktop-padding-bottom-m">
                            Wellness and Requirements for Service: (optional)
                          </label>
                          <InputField type="textarea" v-model="client.clearance_needs" :error="errors.clearance_needs" @input="() => {
                            errors.clearance_needs = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Provider List: (optional)</label>
                          <InputField type="textarea" v-model="client.follow_up_issues" :error="errors.follow_up_issues" @input="() => {
                            errors.follow_up_issues = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Where they stand: (optional)</label>
                          <InputField type="textarea" v-model="client.where_they_stand" :error="errors.where_they_stand" @input="() => {
                            errors.where_they_stand = null;
                          }
                            " />
                        </div>

                        <div class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Repeat check in: (optional)</label>

                          <InputField type="date" id="repeatCheckInDate" v-model="client.repeat_check_in_date" :error="errors.repeat_check_in_date" @input="() => {
                            errors.repeat_check_in_date = null;
                          }
                            " />
                          <div class="invalid-feedback notrepeatCheckInDate" style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>
                        <div class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <InputField class="h-desktop-padding-top-l" type="time" v-model="client.repeat_check_in_time" :error="errors.repeat_check_in_time" @input="() => {
                            errors.repeat_check_in_time = null;
                          }
                            " />
                        </div>
                      </div>
                      <div class="h-input-w49
                        h-desktop-margin-right-2
                        h-col-mobile-12
                        h-margin-bottom-l">
                        <label class="h-label"> MRN number:</label>
                        <InputField type="text" v-model="client.mrn" :error="errors.mrn" @input="() => {
                          errors.mrn = null;
                        }
                          " />
                      </div>
                    </section>
                  </div>
                </div>
                <div class="step-footer h-step-fix-footer">
                  <div class="h-col-desktop-8
                    h-col-screen-m
                    h-col-mobile-12
                    h-margin-auto
                    h-btn-container">
                    <div class="h-steps-btn">
                      <button id="prevBtn" data-step-action="prev" class="step-btn h-btn
                        h-btn-h-bordered
                        h-btn-h-fill-accent
                        h-margin-right-l">
                        Previous
                      </button>
                      <button id="nextBtn" data-step-action="next" class="step-btn h-btn
                        h-btn-h-bordered
                        h-btn-h-fill-accent">
                        Next
                      </button>
                    </div>
                    <div class="h-text-right">
                      <button type="button" class="h-btn
                        h-btn-dangerous
                        h-margin-right-s" @click="cancel">
                        Cancel
                      </button>
                      <!--               <button type="button" class="h-btn h-btn-accent" @click="test(false)">
                        Test
                      </button> -->
                      <button type="button" class="h-btn h-btn-accent" @click="save(false)">
                        Save
                      </button>
                    </div>
                  </div>
                  <div class="h-hide">
                    <ul class="step-steps">
                      <li data-step-target="step1">Step 1</li>
                      <li data-step-target="step2">Step 2</li>
                      <li data-step-target="step3">Step 3</li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-if="loading" class="card-body">
            <Loader />
          </div>
        </div>
      </div>

      />
    </div>
  </div>
</template>
<style>
#MazPhoneNumberInput-79_country_selector {
  height: 25rem;
}

#MazPhoneNumberInput-79_phone_number {
  height: 25rem;
}
</style>
<script>
import moment from 'moment';
import VuePhoneNumberInput from 'vue-phone-number-input';
import Multiselect from 'vue-multiselect';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import Diagnosis from './components/diagnosis/Diagnosis';
// import InsurenceInfo from './components/InsurenceInfo';
import MedicationUse from './components/medicationUse/MedicationUse';
import HospitalSelector from '@/components/HospitalSelector';
import PainCategories from './components/PainCategories';
import eventBus from '../../../event-bus';
import eventKeeper from '../../../eventKeeper';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  data() {
    const currentDate = new Date();
    const oneYearFromNow = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate(),
    )
      .toISOString()
      .slice(0, 10);

    return {
      hospitalId: null,
      locationId: null,

      billingPhysicianId: null,
      surgeonId: null,
      surgeonOptions: [],

      assignedNurseId: null,
      nurseOptions: [],

      physiotherapistId: null,
      physiotherapistOptions: [],
      newPatient: false,
      loading: true,

      client: {
        send_sms: true,
        care_protocol: {
          id: null,
        },
        monitoring_program: null,
        diagnoses: [],
        pain_categories: [],
      },
      formattedPhone: '',
      today_plan_start_date: new Date().toISOString().slice(0, 10),
      year_plan_end_date: oneYearFromNow,
      defaultHeight: '70',
      defaultWeight: '80',
      savedEmail: null,
      errors: {},
      clientSet: false,
      photoUrl: '',
      removePhoto: false,
      genderChoices: [
        [null, ''],
        ['Male', 'Male'],
        ['Female', 'Female'],
      ],
      mobileTypeChoices: [
        [null, ''],
        ['Android', 'Android'],
        ['iOS', 'iOS'],
      ],
      primaryDeviceChoices: [
        [null, ''],
        ['Pulse Oximeter', 'Pulse Oximeter'],
        ['Weighing Scale', 'Weighing Scale'],
        ['Thermometer', 'Thermometer'],
        ['Spirometer', 'Spirometer'],
        ['Blood Pressure Cuff', 'Blood Pressure Cuff'],
        ['Glucometer', 'Glucometer'],
      ],
      accountStatusChoices: [
        ['Pending activation', 'Pending activation'],
        ['Active', 'Active'],
        ['Inactive', 'Inactive'],
      ],
      accountStatus: null,
      selectedCareProtocols: null,
      careProtocols: [],
      formWasSaved: null,
      moment,
      // showNurseSelector: !settings.hasRole("nurse", true), // turn it on for all except nurse
      showNurseSelector: true, // turn it on for all except nurse
      patient_status_id: 0,
      patientStatus: [[null, '']],
      locationChoices: [],
      CareProtocolMedications: 'Yes',
      CareProtocolPT: 'Yes',
      filteredCareProtocols: [],
      careTypes: [
        'Default',
        'TENS',
        'Spondylosis',
        'Spinal Stenosis',
        'Neuropathic Pain',
        'Degenerative Disk Disease',
      ],
      selectedCareType: 'Default',
      isDisabled: true,
      assessments_off_selected: null,
      options: ['Yes', 'No'],
    };
  },

  watch: {
    'client.monitoring_program': function monitoringProgram(newVal) {
      this.isDisabled = true;
      this.filterCareProtocols();
      if (this.selectedCareProtocols != null) {
        this.errors.care_protocol_id = false;
      }
    },
    CareProtocolMedications(newVal) {
      this.isDisabled = true;
      this.filterCareProtocols();
    },
    CareProtocolPT(newVal) {
      this.isDisabled = true;
      this.filterCareProtocols();
    },
    selectedCareType(newVal) {
      this.isDisabled = true;
      this.filterCareProtocols();
    },
    async $route(to, from) {
      await this.onRouteChange();
    },

    accountStatus() {
      if (this.accountStatus === 'Active') {
        this.client.pending_activation = false;
        this.client.is_archived = false;
      }

      if (this.accountStatus === 'Inactive') {
        this.client.is_archived = true;
        this.client.pending_activation = false;
      }

      if (this.accountStatus === 'Pending activation') {
        this.client.pending_activation = true;
        this.client.is_archived = false;
      }
    },

    hospitalId() {
      if (!this.loading) {
        this.onRouteChange(this.hospitalId);
      }
    },
  },

  computed: {
    pageTitle() {
      return this.$route.meta.title;
    },

    isAdmin() {
      return settings.hasRole('system_administrator');
    },

    isAccountInactive() {
      return this.accountStatus === 'Inactive';
    },

    shouldAssignedNurseDropDownBeShown() {
      let result = false;
      const isCurrentUserNurse = settings.hasRole('nurse', true);
      const isCurrentUserNurseAdmin = settings.hasRole('nurse_administrator', true);

      if (isCurrentUserNurse) result = false;

      if (isCurrentUserNurseAdmin) result = true;

      return true;
    },
  },

  methods: {
    enableSelection() {
      this.isDisabled = false;
    },
    filterCareProtocols() {
      let filtered = this.careProtocols;
      if (this.client.monitoring_program) {
        const e = this.client.monitoring_program;
        filtered = filtered.filter(protocol => protocol.label.includes(e));
      }
      if (this.client.monitoring_program === 'RTM') {
        if (this.selectedCareType !== null) {
          filtered = filtered.filter(protocol => protocol.label.includes(this.selectedCareType.replace(/\s/g, '_')));
        }
        filtered = this.applyAdditionalFilters(filtered);
      }
      if (this.client.monitoring_program === 'RPM') {
        filtered = this.applyAdditionalFilters(filtered);
      }
      this.filteredCareProtocols = filtered;
      if (this.filteredCareProtocols && this.filteredCareProtocols.length > 0) {
        this.selectedCareProtocols = this.filteredCareProtocols[0];
      } else {
        this.selectedCareProtocols = null;
      }
    },
    applyAdditionalFilters(filteredProtocols) {
      if (this.CareProtocolMedications !== null) {
        if (this.CareProtocolMedications === 'Yes') {
          filteredProtocols = filteredProtocols.filter(
            protocol => protocol.label.includes('Medications') && !protocol.label.includes('No_Medications'),
          );
        } else {
          filteredProtocols = filteredProtocols.filter(protocol => protocol.label.includes('No_Medications'));
        }
      }
      if (this.CareProtocolPT !== null) {
        if (this.CareProtocolPT === 'Yes') {
          filteredProtocols = filteredProtocols.filter(
            protocol => protocol.label.includes('PT') && !protocol.label.includes('No_PT'),
          );
        } else {
          filteredProtocols = filteredProtocols.filter(protocol => protocol.label.includes('No_PT'));
        }
      }

      return filteredProtocols;
    },
    setHospital(hospital) {
      this.hospital = hospital;
      this.locationId = 0;

      this.locationChoices = [
        {
          value: 0,
          title: '--- no location ---',
        },
      ];
      if (this.hospital) {
        this.hospital.locations.forEach((location) => {
          this.locationChoices.push({
            value: location.id,
            title: location.name,
          });
        });
      }
    },

    clearError() {
      this.errors.care_protocol_id = false;
      this.errors.CareProtocolMedications = false;
      this.errors.CareProtocolPT = false;
    },

    async updateFormattedPhone(info) {
      this.formattedPhone = info.formattedNumber;
    },
    /*     async test(){
            const data = {'hospital_id': 2, 'location_id': '149', 'name': 'test', 'send_sms': true, 'email': '', 'phone': '+18887876884', 'date_of_birth': '2000-01-01', 'gender': 'male', 'height': '70', 'weight': '80', 'billing_physician_id': '3431', 'surgeon_id': '3509', 'assigned_nurse_id': '2099', 'physiotherapist_id': null, 'care_protocol_id': 31, 'removePhoto': false, 'plan_start_date': '2024-11-26', 'plan_end_date': '2025-11-26', 'diagnoses': [], 'is_archived': false, 'pending_activation': false, 'medication_use_template': [], 'monitoring_program': 'RPM', 'patient_status_id': 0, 'is_traced': false, 'pain_categories': [], 'invite_client': false, 'assessments_off': 'Yes'}
            const response = await this.$api.createClient(data);
        }, */
    async save(inviteClient) {
      this.errors = {};
      if (!this.client.name) {
        this.errors.name = true;
      }
      if (!this.client.date_of_birth) {
        this.errors.date_of_birth = true;
      }
      if (!this.client.phone) {
        this.errors.phone = true;
      }
      if (!this.client.gender) {
        this.errors.gender = true;
      }
      if (!this.surgeonId) {
        this.errors.surgeon_id = true;
      }
      if (!this.assignedNurseId) {
        this.errors.assigned_nurse_id = true;
      }
      if (!this.client.monitoring_program) {
        this.errors.monitoring_program = true;
      }
      if (!this.selectedCareProtocols || this.selectedCareProtocols.value === null) {
        this.errors.care_protocol_id = true;
        this.isDisabled = false;
      }
      if (
        (!this.selectedCareProtocols || this.selectedCareProtocols.value === null) &&
        !this.CareProtocolMedications
      ) {
        this.errors.CareProtocolMedications = true;
      }
      if (
        (!this.selectedCareProtocols || this.selectedCareProtocols.value === null) &&
        !this.CareProtocolPT
      ) {
        this.errors.CareProtocolPT = true;
      }
      if (!this.assessments_off_selected) {
        this.errors.assessments_off_selected = true;
      }
      if (Object.keys(this.errors).length > 0) {
        this.formWasSaved = false;
        $.notify('Please fill in all required fields', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.loading = true;
      this.errors = {};
      this.formWasSaved = null;

      const email = this.client.email ? this.client.email.trim() : '';
      // if (!email) {
      //   if (this.client.phone) {
      //     email = `${this.client.phone.trim()}@nomail.healent.com`;
      //   } else {
      //     email = 'none@nomail.healent.com';
      //   }
      // }
      if (this.newPatient) {
        this.client.pending_activation = false;
      }

      const payload = {
        hospital_id: this.hospitalId,
        location_id: this.locationId,
        id: this.client.id,
        name: this.client.name,
        send_sms: this.client.send_sms,
        email,
        phone: this.formattedPhone,
        date_of_birth: this.client.date_of_birth,
        gender: this.client.gender,
        height: this.defaultHeight,
        weight: this.defaultWeight,
        billing_physician_id: this.billingPhysicianId,
        surgeon_id: this.surgeonId,
        assigned_nurse_id: this.assignedNurseId,
        physiotherapist_id: this.physiotherapistId,
        surgery_date: this.client.surgery_date,
        surgery_time: this.client.surgery_time,
        discharge_date: this.client.discharge_date,
        program: this.client.program,
        care_protocol_id: this.selectedCareProtocols.value,
        address: this.client.address,
        A1C: this.client.A1C,
        medical_record_number: this.client.medical_record_number,
        outstanding_issues_or_concerns: this.client.outstanding_issues_or_concerns,
        clearance_needs: this.client.clearance_needs,
        date_seen_for_optimization: this.client.date_seen_for_optimization,
        date_for_return_visit: this.client.date_for_return_visit,
        communication_entries: this.client.communication_entries,
        communication_messages_done: this.client.communication_messages_done,
        follow_up_issues: this.client.follow_up_issues,
        where_they_stand: this.client.where_they_stand,
        repeat_check_in_date: this.client.repeat_check_in_date,
        repeat_check_in_time: this.client.repeat_check_in_time,

        removePhoto: this.removePhoto,

        mrn: this.client.mrn,
        plan_start_date: this.today_plan_start_date,
        plan_end_date: this.year_plan_end_date,
        diagnoses: this.client.diagnoses,

        registration_id: this.client.registration_id,
        voip_registration_id: this.client.voip_registration_id,
        is_archived: this.client.is_archived,
        pending_activation: this.client.pending_activation,
        insurance_info: this.client.insurance_info,
        mobile_type: this.client.mobile_type,
        primary_device: this.client.primary_device,
        medication_use_template: this.client.medication_use_template,
        scs: this.client.scs,
        scs_date: this.client.scs_date,
        scs_satisfaction: this.client.scs_satisfaction,
        monitoring_program: this.client.monitoring_program,
        patient_status_id: this.patient_status_id,
        is_traced: this.client.is_traced || false,
        pain_categories: this.client.pain_categories,
        invite_client: inviteClient,
        assessments_off: this.assessments_off_selected,
      };
      if (this.$refs.photo.files.length !== 0) {
        payload.photo = this.$refs.photo.files[0];
      }
      // console.log('payload', payload);
      const response = await this.$api.createClient(payload);
      if (response.status === 'ok') {
        this.formWasSaved = true;
        this.setClient(response.client);
        await this.$router.push({
          name: 'AllPatients',
          params: {
            id: this.client.id,
          },
        });
      } else {
        this.formWasSaved = false;
        this.errors = response.errors;
        if (this.errors.care_protocol_id) {
          $.notify('Invalid care protocol', {
            position: 'top center',
            className: 'error',
          });
        }
        this.loading = false;
      }
    },

    cancel() {
      this.$router.push({
        name: 'AllPatients',
        params: {
          id: this.client.id,
        },
      });
    },

    setClient(loadedClient) {
      this.client = loadedClient;
      if (this.client.email) {
        if (this.client.email.indexOf('@nomail.healent.com') !== -1) {
          this.client.email = '';
        }
      }
      this.client.send_sms = this.client.send_sms || this.client.send_sms === undefined;
      this.photoUrl = this.client.profile;
      this.billingPhysicianId = this.client.billing_physician
        ? this.client.billing_physician.id
        : null;
      this.surgeonId = this.client.surgeon ? this.client.surgeon.id : null;
      this.assignedNurseId = this.client.assigned_nurse ? this.client.assigned_nurse.id : null;
      this.physiotherapistId = this.client.physiotherapist ? this.client.physiotherapist.id : null;
      this.removePhoto = false;

      if (this.client.care_protocol) {
        delete this.client.care_protocol.name;
      } else {
        this.client.care_protocol = { id: null };
      }
      if (!this.client.diagnoses) {
        this.client.diagnoses = [];
      }
      if (!this.client.medication_use_template) {
        this.client.medication_use_template = [];
      }

      if (this.client.if_inactive_reason_id) {
        this.patient_status_id = this.client.if_inactive_reason_id;
      }
      this.is_traced = this.client.is_traced;

      if (!this.clientSet) {
        this.clientSet = true;
        this.$nextTick(() => {
          $('.dropify')
            .dropify()
            .on('dropify.afterClear', () => {
              this.removePhoto = true;
            });
        });
      }

      if (loadedClient.is_archived) {
        this.accountStatus = 'Inactive';
      } else {
        this.accountStatus = 'Active';
      }

      if (loadedClient.pending_activation) this.accountStatus = 'Pending activation';

      if (!loadedClient.id) {
        if (settings.hasRole('surgeon', true)) {
          this.surgeonId = settings.currentSession.user.id;
          this.billingPhysicianId = settings.currentSession.user.id;
        }
        if (settings.hasRole('physiotherapist', true)) {
          this.physiotherapistId = settings.currentSession.user.id;
        }
      }

      loadedClient.pain_categories = loadedClient.pain_categories ?? [];
    },

    async onRouteChange(switchHospitalToId) {
      this.loading = true;

      if (this.$route.params.id) {
        window.execAfterInitMenuInterface(() => {
          window.initActiveMenu('left-navigation-link>hospital>patients>add-patient', false);
        });
        this.setClient(await this.$api.getClient(this.$route.params.id));
      } else {
        window.execAfterInitMenuInterface(() => {
          window.initActiveMenu('left-navigation-link>hospital>patients>add-patient');
        });
        this.setClient({});
        this.newPatient = true;
        // this.accountStatus === 'Pending activation';
      }

      let hospitalId = switchHospitalToId || this.client.hospital_id;
      if (!hospitalId) {
        const hospitals = await this.$api.getHospitalsList();
        hospitalId = hospitals[0].id;
      }
      this.hospitalId = hospitalId;

      const responseCareProtocols = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/care-protocol/hospital`,
        {
          hospitalID: this.hospitalId,
        },
      );
      const careProtocols = responseCareProtocols.care_protocols.map((protocol) => {
        return { label: protocol.name, value: protocol.id };
      });
      this.careProtocols = careProtocols;

      const surgeonsData = await this.$api.searchSeargeon({
        hospital_id: this.hospitalId,
      });
      this.surgeonOptions = surgeonsData.map((surgeon) => {
        return {
          value: surgeon.id,
          title: surgeon.name,
        };
      });
      this.surgeonOptions.unshift([null, '--- Select Provider ---']);

      const nurseData = await this.$api.searchNurse({
        hospital_id: this.hospitalId,
      });
      this.nurseOptions = nurseData
        .filter((nurse) => {
          return (
            !nurse.email.includes('support+') &&
            (!this.client?.id ||
              nurse.id === this.client?.assigned_nurse?.id ||
              (nurse.allow_patients_hospitals ?? []).includes(this.client?.hospital_id))
          );
        })
        .map((nurse) => {
          return {
            value: nurse.id,
            title: nurse.name,
          };
        });
      this.nurseOptions.unshift([null, '--- Select Nurse ---']);

      // const physiotherapistData = await this.$api.searchPhysiotherapist({
      //   hospital_id: this.hospitalId,
      // });
      // this.physiotherapistOptions = physiotherapistData.map((physiotherapist) => {
      //   return {
      //     value: physiotherapist.id,
      //     title: physiotherapist.name,
      //   };
      // });
      // this.physiotherapistOptions.unshift([null, '--- Select physiotherapist ---']);

      this.loading = false;
    },
  },

  async mounted() {
    console.log(this.client);
    $('.dropify').dropify();
    const response = await this.$api.getClientInactiveReasons();
    response.inactive_reasons.forEach((entry) => {
      const row = [entry.id, entry.reason_description];
      this.patientStatus.push(row);
    });

    await this.onRouteChange();
    $('#AddPatient').steps();
    $(document).ready(() => {
      if (window.location.href.indexOf('edit-patient') > -1) {
        $('#AddPatient').removeClass('step-app');
        $('.h-step-fix-footer').addClass('h-justify-content');
        $('.h-btn-container').css('justify-content', 'center');
        $('.h-steps-btn').hide();
        $('.top-120').css('margin-top', '90rem');
      } else {
        $('#AddPatient').addClass('step-app');
        $('.h-step-fix-footer').removeClass('h-justify-content');
        $('.h-btn-container').css('justify-content', 'space-between');
        $('.h-steps-btn').show();
        $('.top-120').css('margin-top', '0rem');
      }
      $('#phone').keyup(() => {
        this.value = this.value.replace(/[^+0-9]/g, '');
      });
      $('#DateOfBirth').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#DateOfBirth').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate || year === null) {
          $('#DateOfBirth').addClass('is-invalid');
          $('#DateOfBirth').val('');
          $('#DateOfBirth').focus();
          $('.notDateOfBirth').css('display', 'block');
        } else {
          $('#DateOfBirth').removeClass('is-invalid');
          $('.notDateOfBirth').css('display', 'none');
        }
      });
      $('#PlanEndDate').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#PlanEndDate').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate + 100) {
          $('#PlanEndDate').addClass('is-invalid');
          $('#PlanEndDate').val('');
          $('#PlanEndDate').focus();
          $('.notPlanEndDate').css('display', 'block');
        } else {
          $('#PlanEndDate').removeClass('is-invalid');
          $('.notPlanEndDate').css('display', 'none');
        }
      });
      $('#SurgeryDate').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#SurgeryDate').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate + 100) {
          $('#SurgeryDate').addClass('is-invalid');
          $('#SurgeryDate').val('');
          $('#SurgeryDate').focus();
          $('.notSurgeryDate').css('display', 'block');
        } else {
          $('#SurgeryDate').removeClass('is-invalid');
          $('.notSurgeryDate').css('display', 'none');
        }
      });
      $('#repeatCheckInDate').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#repeatCheckInDate').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate + 100) {
          $('#repeatCheckInDate').addClass('is-invalid');
          $('#repeatCheckInDate').val('');
          $('#repeatCheckInDate').focus();
          $('.notrepeatCheckInDate').css('display', 'block');
        } else {
          $('#repeatCheckInDate').removeClass('is-invalid');
          $('.notrepeatCheckInDate').css('display', 'none');
        }
      });
      const validateEmail = (email) => {
        const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.([a-zA-Z]){2,}$/;
        let isEmailValid = false;
        if (email.match(pattern) != null) {
          isEmailValid = true;
        }
        return isEmailValid;
      };
      eventKeeper.$on(this, 'keyup', document, '#inviteEmail', (event) => {
        const keyPressed = event.which;
        const inputVal = $(this).val();
        let isSuccess = true;
        if (keyPressed === 9) {
          isSuccess = validateEmail(inputVal);
          if (!isSuccess) {
            $(this).focus();
          }
        }
      });

      eventBus.$documentOn(
        'focusout',
        '#inviteEmail',

        this,
      );

      eventKeeper.$on(this, 'focusout', document, '#inviteEmail', () => {
        const inputVal = $(this).val();
        const isSuccess = validateEmail(inputVal);

        if (!isSuccess) {
          $('#inviteEmail').focus();
          $('#inviteEmail').addClass('is-invalid');
          $('#notanEmail').css('display', 'block');
        } else {
          $('#inviteEmail').removeClass('is-invalid');
          $('#notanEmail').css('display', 'none');
        }
      });
    });
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    eventKeeper.$offOwner(this);
  },

  components: {
    Loader,
    InputField,
    Diagnosis,
    // InsurenceInfo,
    MedicationUse,
    HospitalSelector,
    PainCategories,
    VuePhoneNumberInput,
    Multiselect,
  },
};
</script>
